import apiClient from './axiosConfig';

// Инициация проверки телефона
export const apiRequestCode = async (phone) => {
  const response = await apiClient.post('/auth/request-code', { phone });
  return response;
};

// Проверка кода
export const apiVerifyCode = async (phone, code) => {
  const deviceType = localStorage.getItem('deviceType');
  const response = await apiClient.post('/auth/verify-code', { phone, code, deviceType: deviceType});
  return response.data;
};

// Получение информации пользователя
export const apiGetUserProfile = async () => {
  const response = await apiClient.get('/user/profile');
  //const response = await apiClient.get('/user/profile', {cache: {interpretHeader:false}});
  return response?.data;
};

// Обновление информации пользователя
export const apiSetUserProfile = async (data) => {
  const response = await apiClient.post('/user/update', data);

  return response.data;
};

// Создание профиля
export const apiCreateUserProfile = async (data) => {
  const response = await apiClient.post('/user/register', data);

  return response.data;
};


export const updateUserAvatar = async (avatar) => {

  const formData = new FormData();

  if (avatar && avatar.files[0]) {
    formData.append('avatar', avatar.files[0] || '');
  }

  const response = await apiClient.post('/user/update', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}
