import apiClient from './axiosConfig';
import axios from "axios";
import {useChatStore} from "../store/chat-store";
import {useUserStore} from "../store/user-store";

export const apiChatSendMessage = async (message, fileInput) => {
  const formData = new FormData();

  formData.append('message', message);
  formData.append('name', localStorage.getItem('name'));
  formData.append('phone', localStorage.getItem('phone'));
  formData.append('email', localStorage.getItem('email'));
  formData.append('files', fileInput.files[0]||'');
  formData.append('type', 'send_message');


  const response = await apiClient.post(
    // 'https://b24barcelona.m-works.ru/pub/b24test/ajax.php',
    'https://b24barcelona-stage.m-works.ru/pub/b24test/ajax.php', //для m-stage.barcelona.m-works.ru
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data;
};

export const apiChatGetHistory = async () => {
  const formData = new FormData();
  formData.append('name', localStorage.getItem('name'));
  formData.append('phone', localStorage.getItem('phone'));
  formData.append('type', 'chat_history');
  const response = await apiClient.post(
    // 'https://b24barcelona.m-works.ru/pub/b24test/ajax.php',
    'https://b24barcelona-stage.m-works.ru/pub/b24test/ajax.php', //для m-stage.barcelona.m-works.ru
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response?.data;
}
