import {motion} from "framer-motion";
import {useForm} from "react-hook-form";
import {useUserStore} from "../../store/user-store";
import Input from "../Input";
import Icon from "../Icon";

const ModalSettingsCity = ({active, city, onClose}) => {

  const [submitPersonalInfo] = useUserStore((state) => [state.submitPersonalInfo])

  const {register, formState, handleSubmit, watch} = useForm({
    defaultValues: {
      city: city,
    }
  });

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };


  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-settings modal-settings-city modal-w-inputs ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 mb_28">Город по прописке</h2>
            <form onSubmit={handleSubmit((data) => {
              submitPersonalInfo(data)
              onClose();
            })} className="form-change-email" data-setting="city">
              <div className="fieldset fieldset_gap-16">
                <Input
                  value={watch('city')}
                  placeholder="Город"
                  error={formState.errors.city?.message}
                  {...register("city", {required: 'Введите город'})}
                  ref={null}
                  defaultValue={city}
                />
              </div>
              <button type="submit" className="button save-settings">Сохранить изменения</button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalSettingsCity