import {create} from 'zustand';
import apiClient from "../api/axiosConfig";
import {apiChatGetHistory} from "../api/chatApi";

export const useChatStore = create((set) => ({
  chat: [],
  selectedMessageId: null,
  replyMessageId: null,
  chatModalOpen: false,
  fileUploadingStatus: false,
  updateChat: async () => {
    //const response = await apiClient.get(`${process.env.REACT_APP_REST_SERVER_URL}/chat`)
    //set({chat: await response.data})

    const messages = []
    const data = await apiChatGetHistory()


    if (data) {
      for (const property in data) {
        if (data[property]['user']) {
          const message = {
            "id": messages.length,
            "senderStatus": "user",
            "senderName": data[property]['user']['name'],
            "text": data[property]['message']['text'],
            //"answerMessageId": null,
            "date": data[property]['message']['date'],
            "files": data[property]['message']['files'] || []
          }
          messages.push(message)
        } else {

          const message = {
            "id": messages.length,
            "senderStatus": "clinic",
            "senderName": 'Оператор клиники',
            "text": data[property]['message']['text'],
            //"answerMessageId": null,
            "date": data[property]['date'],
            "files": data[property]['files'] || []
          }
          messages.push(message)
        }
      }
    }

    // TODO: придумать другой способ отслеживания изменений стора
    if(JSON.stringify(messages) !== JSON.stringify(useChatStore.getState().chat)) {
      set({chat: messages})
    }
  },
  addChatMessage: (data) => set((state) => ({chat: [...state.chat, data]})),
  updateSelectedMessageId: (data) => set(() => ({selectedMessageId: data})),
  updateReplyMessageId: (data) => set(() => ({replyMessageId: data})),
  updateChatModalOpen: (data) => set(() => ({chatModalOpen: data})),
  updateFileUploadingStatus: (data) => set(() => ({fileUploadingStatus: data})),
}))