import {motion} from "framer-motion";
import {useForm} from "react-hook-form";
import Input from "../Input";
import {useUserStore} from "../../store/user-store";
import Icon from "../Icon";

const ModalSettingsEmail = ({active, email, onClose}) => {

  const [submitPersonalInfo] = useUserStore((state) => [state.submitPersonalInfo])

  const {register, formState, handleSubmit, watch} = useForm({
    defaultValues: {
      email: email,
    }
  });

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-settings modal-settings-email modal-w-inputs ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 mb_28">E-mail</h2>
            <form onSubmit={handleSubmit((data) => {
              submitPersonalInfo(data);
              onClose();
            })} className="form-change-email" data-setting="email">
              <div className="fieldset fieldset_gap-16">
                <Input
                  value={watch('email')}
                  placeholder="E-mail"
                  error={formState.errors.email?.message}
                  {...register("email", {
                    required: 'Введите E-mail',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Некорректный E-mail"
                    }
                  })}
                  ref={null}
                  defaultValue={email}
                />
              </div>
              <button type="submit" className="button save-settings">Сохранить изменения</button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalSettingsEmail