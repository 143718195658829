import {useEffect, useRef, useState} from "react";

export const ChatDate = ({date}) => {
  const [isOverlapping, setIsOverlapping] = useState(false);
  // console.log("is it true? =>", isOverlapping);

  const wrapper = useRef(null);

  const checkIfHeaderIsOverlapping=()=> {
    // console.log(date, wrapper.current.getBoundingClientRect())
    if(wrapper.current?.getBoundingClientRect().y < 80) {
      wrapper.current.querySelector('.chat__date-separator').classList.add('chat__date-fixed')
    }
    else {

      wrapper.current.querySelector('.chat__date-separator').classList.remove('chat__date-fixed')
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", checkIfHeaderIsOverlapping);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", checkIfHeaderIsOverlapping);
    };
  }, [])
  return (
    <div ref={wrapper}>
    <div className="chat__date-separator">{date}</div></div>
  )
}
