import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Icon from "../Icon";

const ModalCallsAccess = ({ active, onClose }) => {
  const [classVisible, setClassVisible] = useState('');
  const [classFade, setClassFade] = useState('');

  useEffect(() => {
    if (active) {
      setClassVisible('modal_visible');
      setTimeout(() => {
        setClassFade('modal_fade');
      }, 1);
    } else {
      setClassFade('');
      setTimeout(() => {
        setClassVisible('');
      }, 300);
    }
  }, [active]);
  return (
    <div className={`modal modal_w-close modal modal-chats-access ${classVisible} ${classFade}`} data-popup="callsAccess">
      <div className="modal__inner">
        <div className="modal__wrap">
          <div className="modal__close modal-close" onClick={onClose}>
            <Icon name="close" />
          </div>
          <div className="modal__content">
            <div className="modal__picture modal__picture_calls"></div>
            <h2 className="h2 modal__title mb_24">Авторизируйтесь <br/>для доступа к звонкам</h2>
            <div className="modal__buttons">
              <Link className="button" to="/register">Авторизироваться</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalCallsAccess