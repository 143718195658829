import {motion} from "framer-motion";
import {useForm} from "react-hook-form";
import Input from "../Input";
import {useUserStore} from "../../store/user-store";
import Icon from "../Icon";

const ModalSettingsName = ({active, onClose}) => {

  const [firstName, secondName, lastName, birthDate, city, submitPersonalInfo] = useUserStore((state) => [state.firstName, state.secondName, state.lastName, state.birthDate, state.city, state.submitPersonalInfo])

  const {register, formState, handleSubmit, watch} = useForm({
    defaultValues: {
      firstName: firstName || '',
      secondName: secondName || '',
      lastName: lastName || '',
    }
  });

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-settings modal-settings-name modal-w-inputs ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 mb_28">Имя</h2>
            <form onSubmit={handleSubmit((data) => {
              submitPersonalInfo(data);
              onClose();
            })}
                  className="form-change-name" data-setting="user-name">
              <div className="fieldset fieldset_gap-16">
                <Input
                  value={watch('lastName')}
                  placeholder="Фамилия"
                  error={formState.errors.lastName?.message}
                  {...register("lastName", {
                    required: 'Введите фамилию',
                    minLength: {value: 2, message: 'Минимум 2 символа'}
                  })}
                  ref={null}
                  defaultValue={lastName}
                />
                <Input
                  value={watch('firstName')}
                  placeholder="Имя"
                  error={formState.errors.firstName?.message}
                  {...register("firstName", {required: 'Введите имя', minLength: {value: 2, message: 'Минимум 2 символа'}})}
                  ref={null}
                  defaultValue={firstName}
                />
                <Input
                  value={watch('secondName')}
                  placeholder="Отчество"
                  error={formState.errors.secondName?.message}
                  {...register("secondName", {
                    required: 'Введите отчество',
                    minLength: {value: 2, message: 'Минимум 2 символа'}
                  })}
                  ref={null}
                  defaultValue={secondName}
                />
              </div>
              <button type="submit" className="button save-settings">Сохранить изменения</button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ModalSettingsName