import {useNavigate} from "react-router-dom";
import ScreenPin from "../components/screens/ScreenPin";
import LayoutAuth from "../components/layouts/LayoutAuth";
import {apiGetUserProfile} from "../api/userApi";
import {useEffect} from "react";

const PageLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('access_token') && localStorage.getItem('refresh_token')) {
      const device = localStorage.getItem('deviceType');

      if (device !== 'web') {
        navigate('/login');
      } else {
        navigate('/main-auth');
      }
    }
  }, []);

  return (
    <LayoutAuth>
      <ScreenPin
        mode="enter"
        toPrevStep={() => navigate('/')}
        toNextStep={() => navigate('/main-auth')}
      />
    </LayoutAuth>
  )
}
export default PageLogin