import apiClient from "./axiosConfig";
import {pushPublicKey} from "../utils/consts";

export async function subscribePush () {
  return navigator.serviceWorker.ready
    .then(reg => {
      return reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushPublicKey
      }).then(sub => apiClient.post(`/push/subscribe`, JSON.stringify(sub)))
    })
}