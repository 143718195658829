import { create } from 'zustand';
import {apiGetStories} from "../api/storiesApi";

export const useStoriesStore = create((set) => ({
  stories: {},
  getStories: async () => {
    const response = await apiGetStories()
    const storiesArr = []
    const list = await response.data.items
    Object.keys(list).forEach(function(key, index) {
      storiesArr.push(list[key])
    })
    set({stories: storiesArr})
  },
}))