import React, {useEffect, useRef, useState} from 'react';
import {format} from 'date-fns';
import cn from 'classnames';
import Icon from "../Icon";
import {useChatStore} from "../../store/chat-store";
import bbCodeToHtml from "../../utils/bbCodeToHtml";
import useLongPress from "../../utils/hooks/useLongPress";
import {Image} from 'antd';


const MessageItem = ({message}) => {
  const chat = useChatStore((state) => state.chat);
  const onLongPress = useLongPress();


  const updateSelectedMessageId = useChatStore((state) => state.updateSelectedMessageId)
  const updateChatModalOpen = useChatStore((state) => state.updateChatModalOpen)

  const messageActionsModal = (active, messageId) => {
    if (active) {
      updateChatModalOpen(active)
      updateSelectedMessageId(messageId)
    } else {
      updateChatModalOpen(false)
      updateSelectedMessageId(null)
    }
  }
  const ref = useRef(null)
  useEffect(() => {
    ref.current.addEventListener('contextmenu', function (ev) {
      ev.preventDefault();
      messageActionsModal(true, message.id)
      return false;
    }, false);
  }, [ref])

  // console.log(message)


  return (
    <div
      className={cn('message', {'message_outgoing': message.senderStatus === 'user'}, {'message_incoming': message.senderStatus === 'clinic'})}
      data-name={message.senderName}
      ref={ref}
    >
      <div
        className="message__inner"
        {...onLongPress(() => messageActionsModal(!message?.files.length && true, message.id))}
      >
        {/*
          message.answerMessageId && (
            <div className="message__quote">
              <div className="message__quote-name">{chat.find((el) => el.id === message.answerMessageId).senderName}</div>
              <div className="message__quote-text">{chat.find((el) => el.id === message.answerMessageId).text}</div>
            </div>
          )
        */}
        {/*<div className="message__text">{message.text}</div>*/}
        {message?.files && message?.files.length > 0 && (
          <span>
            {message.files.map((file, index) => {

              console.log(file)

              return (
                <div className="message__file-box" key={index}>
                  {file.type === 'image' && (
                    <Image
                      preview={{
                        mask: false,
                        toolbarRender: () => false,
                        destroyOnClose: true,
                        movable: false
                      }}
                      className="message__file-image"
                      src={file.link}
                      alt={file.name}
                    />
                  )}
                  {file.type !== 'image' && (
                      <span>Ссылка для скачивания: &nbsp;
                        <a href={file.link} className='message__file-doc' download={file.ORIGINAL_NAME}>{file.ORIGINAL_NAME}</a>
                      </span>
                  )}
                </div>
              )
            })}</span>
        )}
        <div className="message__text" dangerouslySetInnerHTML={{__html: bbCodeToHtml(message.text)}}/>
        <div className="message__info">
          <div className="message__time">{format(new Date(message.date * 1000), 'HH:mm')}</div>
          <div className="message__status">
            <Icon name="tick-12"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
