import {motion} from "framer-motion";
import {useForm} from "react-hook-form";
import {useUserStore} from "../../store/user-store";
import MaskedInput from "../MaskedInput";
import Icon from "../Icon";
import {validateDate} from "../../utils/date";

const ModalSettingsBirthday = ({active, onClose}) => {

  const [birthDate, submitPersonalInfo] = useUserStore((state) => [state.birthDate, state.submitPersonalInfo])

  const {register, formState, handleSubmit, watch} = useForm({
    defaultValues: {
      birthDate: birthDate,
    }
  });

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-settings modal-settings-birthday modal-w-inputs ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 mb_28">Дата рождения</h2>
            <form onSubmit={handleSubmit((data) => {
              submitPersonalInfo(data);
              onClose();
            })} className="form-change-email" data-setting="birthday">
              <div className="fieldset fieldset_gap-16">
                <MaskedInput
                  value={watch('birthDate')}
                  labelText="Дата рождения"
                  mask={Date}
                  error={formState.errors.birthDate?.message}
                  {...register("birthDate", {
                    required: "Введите дату рождения",
                    validate: value => validateDate(value)
                  })}
                  ref={null}
                  defaultValue={birthDate}
                />
              </div>
              <button type="submit" className="button save-settings">Сохранить изменения</button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ModalSettingsBirthday