import { create } from 'zustand';
import apiClient from "../api/axiosConfig";

export const useQaStore = create((set) => ({
  qa: [],
  updateQa: async () => {
    const response = await apiClient.get(`/faq?nPageSize=999&page=1`)
    const result = await response.data
    set({qa: result?.data?.items})
  },
}))