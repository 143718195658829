export default function bbCodeToHtml(text) {
  // Замена >> на <cite>...</cite>
  text = text.replace(/<br>>>(.*)/g, '<cite>$1</cite>');

  // Замена ---------...--------- на <cite>...</cite>
  text = text.replace(/-{54,}([\s\S]*)-{54,}/g, '<div class="message__quote"><div class="message__quote-text">$1</div></div>');

  // Замена [b]...[/b] на <strong>...</strong>
  text = text.replace(/\[b\](.*?)\[\/b\]/g, '<strong>$1</strong>');

  // Замена [i]...[/i] на <em>...</em>
  text = text.replace(/\[i\](.*?)\[\/i\]/g, '<em>$1</em>');

  // Замена [u]...[/u] на <u>...</u>
  text = text.replace(/\[u\](.*?)\[\/u\]/g, '<u>$1</u>');

  // Замена [url=...]...[/url] на <a href="...">...</a>
  text = text.replace(/\[url=(.*?)\](.*?)\[\/url\]/g, '<a href="$1">$2</a>');

  // Замена [img]...[/img] на <img src="..." alt="image">
  text = text.replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" alt="image">');

  // Замена [code]...[/code] на <pre><code>...</code></pre>
  text = text.replace(/\[code\](.*?)\[\/code\]/g, '<pre><code>$1</code></pre>');

  // Замена [br] на <br>
  text = text.replace(/\[br\]/g, '<br>');

  return text;
}