import {motion} from "framer-motion";
import Icon from "../Icon";
import {Link} from "react-router-dom";

const ModalChatsAccess = ({active, onClose}) => {


  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_w-close modal modal-chats-access ${active ? 'modal--show' : ''}`}
      data-popup="chatsAccess">
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <div className="modal__picture modal__picture_chats"></div>
            <h2 className="h2 modal__title mb_24">Авторизируйтесь <br/>для доступа к чатам</h2>
            <div className="modal__buttons">
              <Link className="button" to="/register">Авторизироваться</Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalChatsAccess