import {format} from "date-fns";
import {ru} from "date-fns/locale";

export const prettyDate = (timestamp) => {
  return format(new Date(+timestamp * 1000), 'd MMMM', {locale: ru})
}

export const convertDate = (dateString) => {

  const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
  if (datePattern.test(dateString)) {
    // Разбиваем строку на части
    const parts = dateString.split('.');
    // Проверяем, что строка имеет правильный формат
    if (parts.length !== 3) {
      throw new Error('Неверный формат даты');
    }
    // Создаем объект Date с правильным порядком частей
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    // Проверяем, что дата корректна
    if (isNaN(date.getTime())) {
      throw new Error('Неверная дата');
    }
    // Возвращаем дату в формате ISO 8601
    return date.toISOString().split('T')[0];
  }
}

export const validateDate = (value) => {

  const currentDate = new Date ();
  const selectedDate = new Date(convertDate(value))

  return (
    selectedDate < currentDate || "Некорректная дата"
  );
};