import {motion} from "framer-motion";
import {Link, useNavigate} from "react-router-dom";
import {useUserStore} from "../../store/user-store";
const ModalIncorrectPassword = ({showModal, setShowModal}) => {
  const [logout] = useUserStore((state) => [state.logout])
  const navigate = useNavigate()

  const show = {
    opacity: 1,
    display: "block"
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };
  const  handleClick  =  ()  =>  {
    navigate("/login-new-password")
    logout()
  }
  return (
    <motion.div
      animate={showModal ? show : hide}
      className="modal modal-incorrect-password"
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div className="modal__content">
            <h2 className="h2 modal__title mb_12">Пароль введен <br/>неправильно более 5 раз</h2>
            <div className="text modal__text">Чтобы восстановить пароль, нужно заново пройти авторизацию</div>
            <div className="modal__buttons">
              <button className="button" onClick={handleClick}>Установить новый пароль</button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalIncorrectPassword