import React, {useEffect, useRef, useState} from 'react';
import Icon from "../Icon";
import {useChatStore} from "../../store/chat-store";
import {useUserStore} from "../../store/user-store";
import {apiChatSendMessage} from "../../api/chatApi";
import {LoadingOutlined} from '@ant-design/icons';

const ChatFooter = () => {

  const {firstName, lastName, email} = useUserStore((state) => [state.firstName, state.lastName, state.email]);

  const chat = useChatStore((state) => state.chat);
  const addChatMessage = useChatStore((state) => state.addChatMessage);
  const replyMessageId = useChatStore((state) => state.replyMessageId);
  const updateReplyMessageId = useChatStore((state) => state.updateReplyMessageId);

  const fileUploadingStatus = useChatStore((state) => state.fileUploadingStatus);
  const updateFileUploadingStatus = useChatStore((state) => state.updateFileUploadingStatus);

  const [messageText, setMessageText] = useState('');

  const textChange = (e) => {
    setMessageText(e.target.value)
  }

  const textField = useRef();
  const fileField = useRef();

  const sendMessage = () => {
    let msgText = messageText.trim()
    if (msgText.trim() || fileField.current.value.length) {
      let messageSendTime = +new Date() / 1000;
      //console.log('---------------', messageSendTime)
      //return

      if (fileField.current.files.length && fileField.current.files[0]) {
        const maxAllowedSize = 25 * 1024 * 1024;
        if (fileField.current.files[0].size > maxAllowedSize) {
          alert('Размер файла не должен превышать 25мб')
          fileField.current.value = ''
          if (!msgText.length) return
        }
      }

      if (replyMessageId) {
        msgText = `
        [b]${chat.find((message) => message.id === replyMessageId).senderName}[/b]
        ------------------------------------------------------
        ${chat.find((message) => message.id === replyMessageId).text}
        ------------------------------------------------------
        ${msgText}`
      }

      addChatMessage({
        id: chat.length + 1,
        senderStatus: 'user',
        senderName: `${firstName} ${lastName}`,
        email: email,
        text: msgText,
        answerMessageId: replyMessageId ? replyMessageId : null,
        date: messageSendTime,
      })
      setMessageText('')
      replyMessageId && updateReplyMessageId(null)
      textField.current.focus();

      updateFileUploadingStatus(true);

      apiChatSendMessage(msgText, fileField.current).then((response) => {
        updateFileUploadingStatus(false);
        //console.log(response)
        if (fileField.current && fileField.current.value !== null) {
          fileField.current.value = ''
        }
      })
    }
  }

  const cancelReplayMessage = () => {
    replyMessageId && updateReplyMessageId(null)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  useEffect(() => {
    if (replyMessageId !== null) {
      textField.current.focus();
    }

  }, [replyMessageId]);

  return (
    <div className="chat-actions">
      <div className="container container_12">
        {replyMessageId && (
          <div className="chat-actions-quote">
            <div className="message__quote">
              <div
                className="message__quote-name">{chat.find((message) => message.id === replyMessageId).senderName}</div>
              <div className="message__quote-text">{chat.find((message) => message.id === replyMessageId).text}</div>
            </div>
            <span onClick={cancelReplayMessage} className="chat-actions-quote__cancel">
              <Icon name="close"/>
            </span>
          </div>
        )}
        <div className="chat-actions__inner">
          <form className="chat-attachment-form">
            <label className="chat-attachment">
              {fileUploadingStatus ? <div className="message__loader"><LoadingOutlined/></div> : (
                <>
                  <Icon name="attachment"/>
                  <input
                    className="chat-attachment__input"
                    type="file"
                    ref={fileField}
                    onChange={sendMessage}
                    accept="image/*,video/*,.pdf,.doc,.pdf,docx, .xls,.xlsx,.pptx,.ppt,.pptx,.txt,.rtf"
                    maxLength={111}
                  />
                </>
              )}
            </label>
          </form>
          <div className="chat-input-text">
            <textarea ref={textField} className="chat-input-text__textarea" onKeyDown={handleKeyDown}
                      onChange={textChange} value={messageText}
                      placeholder="Сообщение..."></textarea>
          </div>
          <button onClick={sendMessage} className="chat-send-message">
            <Icon name="send"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatFooter;
