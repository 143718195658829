import React, {useEffect, useRef, useState} from 'react';
import handleViewport from 'react-in-viewport'
import MessageItem from "./message-item";
import {useChatStore} from "../../store/chat-store";
import {useStore} from "zustand";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {prettyDate} from "../../utils/date";
import {ChatDate} from "./chat-date";

const MessageList = () => {
  window.__localeId__ = "ru";

  const chat = useChatStore((state) => state.chat);
  const chatEndRef = useRef(null);
  const [fixedDate, setFixedDate] = useState();
  let tmpDate = null;

  const scrollToBottom = () => {
    if (chat.length !== 0 && chatEndRef.current) {
      setTimeout(() => {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  return (
    <div className="main chat">
      <div className="chat__flex container container_12">
        {/*fixedDate && (<div className="chat__date-fixed">{prettyDate(fixedDate)}</div>)*/}
        {chat.length ? chat.map((message, index) => {
          const separatorDate = (tmpDate === prettyDate(message.date) ? null : prettyDate(message.date))
          if (!tmpDate || tmpDate !== prettyDate(message.date)) tmpDate = prettyDate(message.date);
          return (
            <>
              {separatorDate && (<ChatDate date={separatorDate}/>)}
              <MessageItem key={message.id} message={message}/>
            </>
          )
        }) : null}
        <span ref={chatEndRef} className="chat__end"></span>
      </div>
    </div>
  );
};

export default MessageList;
