import create from 'zustand';
import {
  apiRequestCode,
  apiVerifyCode,
  apiSetUserProfile, apiLogout, apiGetUserProfile, apiCreateUserProfile
} from '../api/userApi';

export const useUserStore = create((set, get) => ({
  phone: '',
  pin: localStorage.getItem('pin') || '',
  avatar: '',
  firstName: '',
  secondName: '',
  lastName: '',
  birthDate: '',
  email: localStorage.getItem('email') || '',
  city: '',
  push: false,
  accessToken: localStorage.getItem('access_token') || '',
  refreshToken: localStorage.getItem('refresh_token') || '',
  isAuthenticated: false,

  // Методы для обновления состояния
  setPhone: (phone) => {
    localStorage.setItem('phone', phone);
    return set({ phone })
  },
  setEmail: (email) => {
    localStorage.setItem('email', email);
    return set({ email })
  },
  setPin: (pin) => set({ pin }),
  setAvatar: (avatar) => set({ avatar }),
  confirmPin: (pin) => localStorage.setItem('pin', pin),
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated  }),

  // Методы для выполнения запросов и обновления состояния
  sendPhoneNumber: async () => {
    const phone = get().phone;
    try {
      return await apiRequestCode(phone);
    } catch (error) {
      throw error;
    }
  },

  verifyPhoneNumber: async (phone, verificationCode) => {
    try {
      const response = await apiVerifyCode(phone, verificationCode);
      if(response.status === 'success')  {
        set({ accessToken: response.data.accessToken, refreshToken: response.data.refreshToken, isAuthenticated: true });
        localStorage.setItem('access_token', response.data.accessToken);
        localStorage.setItem('refresh_token', response.data.refreshToken);
        return true;
      }
      else {
        //throw new Error(response.errors.message);
      }
    } catch (error) {
      return false
    }
  },

  submitPersonalInfo: async (data) => {
    try {
      const response = await apiSetUserProfile({...data});
      await set({...data});
      if(data.firstName && data.lastName) {
        localStorage.setItem('name', data.firstName + ' ' + data.lastName);
      }
      if(data.email) {
        localStorage.setItem('email', data.email);
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  createUser: async (data) => {
    try {
      const response = await apiCreateUserProfile({...data});
      await set({...data});
      if(data.firstName && data.lastName) {
        localStorage.setItem('name', data.firstName + ' ' + data.lastName);
      }
      if(data.phone) {
        localStorage.setItem('phone', data.phone);
      }
      if(data.email) {
        localStorage.setItem('email', data.email);
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  getPersonalInfo: async () => {
    try {
      const response = await apiGetUserProfile();
      if(response) {
        let newBirthDate = '';
        if(response.data.birthDate) {
          newBirthDate = new Date(response.data.birthDate).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        }
        await set({...response.data,birthDate:newBirthDate});
        localStorage.setItem('name', response.data.firstName + ' ' + response.data.lastName);
        localStorage.setItem('email', response.data.email);
      }

      return response;
    } catch (error) {
      throw error;
    }
  },

  logout: () => {
    set({ accessToken: '', refreshToken: '', isAuthenticated: false });
    localStorage.clear();
  },
}));
