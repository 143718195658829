import Icon from "@components/Icon";
import {Link} from "react-router-dom";
import ModalCallsAccess from "./modals/ModalCallsAccess";
import {useState} from "react";
const Header = ({isAuth = false}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  return (
    <>
    <header className="header">
      <div className="header__flex container">
        {isAuth && (
          <div className="header__logo"></div>
        )}
        <div className="header__actions">
          {isAuth && (
            <>
              <Link className="header__call" to="/call">
                <Icon name="phone" />
              </Link>
              <Link className="header__notifications" to="/notifications">
                <Icon name="notifications" />
                <span>15</span>
              </Link>
            </>
          )}
          {!isAuth && (
            <span className="header__call" data-popup="callsAccess" onClick={()=>setIsModalActive(true)}>
							<Icon name="phone" />
            </span>
          )}
        </div>
      </div>
    </header>
  <ModalCallsAccess active={isModalActive} onClose={()=>setIsModalActive(false)}/>
    </>
  );
}

export default Header;