import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import Icon from "../Icon";
import {useUserStore} from "../../store/user-store";
import ModalForgotPassword from "./ModalForgotPassword";


const ModalSettingsPassword = ({active, onClose}) => {


  const code = useUserStore((state) => state.pin);
  const updateCode = useUserStore((state) => state.setPin);
  const confirmCode = useUserStore((state) => state.confirmPin);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'Забыли пароль?', 0];

  const [error, setError] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [inputField, setInputField] = useState('');

  const [newPass, setNewPass] = useState('');

  const [showModalNewPass, setShowModalNewPass] = useState(false);


  const goNext = () => {
    setCurrentStep(prevState => prevState + 1)
  }

  const writeCode = (e) => {
    e.preventDefault();
    if (inputField.length < 4) {
      setInputField(inputField + e.currentTarget.value)
    }
    if (inputField.length === 4 && error) {
      setInputField(e.currentTarget.value)
      setError(false)
    }
  }

  const clearCode = (e) => {
    e.preventDefault();
    setInputField(inputField.slice(0, -1))
    setError(false)
  }

  const closePasswordModal = () => {
    onClose();
    setInputField('');
    setNewPass('');
    setError(false);
    setCurrentStep(1);
  }

  useEffect(() => {
    if (inputField.length === 4 && currentStep === 1) {
      if (inputField === code) {
        goNext();
        setInputField('')
        setError(false)
      } else {
        setError(true)
      }
    }
    if (inputField.length === 4 && currentStep === 2) {
      goNext();
      setNewPass(inputField);
      setInputField('')
    }
    if (inputField.length === 4 && currentStep === 3) {
      if (inputField === newPass) {
        updateCode(newPass);
        confirmCode(newPass);
        closePasswordModal();
      } else {
        setError(true)
      }
    }

  }, [inputField]);

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };


  return (
    <>
      <motion.div
        animate={active ? show : hide}
        className={`modal modal_type-2 modal-settings modal-settings-password ${active ? 'modal--show' : ''}`}
      >
        <div className="modal__inner">
          <div className="modal__wrap">
            <div onClick={onClose} className="modal__close modal-close">
              <Icon name="close"/>
            </div>
            <div className="modal__content">
              <div className="modal__header">
                <div className="modal__name">Изменить пароль</div>
                <div onClick={closePasswordModal} className="modal__close modal-close">
                  <Icon name="close"/>
                </div>
              </div>
              <div className="modal__content modal-setting">
                <form className="modal-setting-step active modal-setting-phone-form" data-step="1" data-pass-step="0">
                  <div className="content-block">
                    <div className="modal-setting__title modal-setting__title_password">
                      <h2 className="h2 ta_c">{currentStep === 1
                        ? 'Введите старый пароль'
                        : currentStep === 2
                          ? 'Новый пароль'
                          : 'Повторите новый пароль'}<br/></h2>
                    </div>
                    <div className="fieldset">
                      <div className="field field_center">
                        <div className={`input-password ${error && 'error'}`}>
                          <input className="input-password__input" type="text" name="password"/>
                          {Array.from({length: 4}, (_, index) => (
                            <div key={index}
                                 className={`input-password__number ${index < inputField.length && 'active'}`}></div>
                          ))}
                          {error && <div className="field__error">Неправильный пароль</div>}
                        </div>
                        <div className="number-panel">
                          {numbers.map((value, index) => {
                            return value !== 'Забыли пароль?' ? (
                              <button key={index} onClick={writeCode} value={value}
                                      className="number-panel__button">{value}</button>
                            ) : (
                              <div key={index} onClick={() => {
                                onClose()
                                setShowModalNewPass(true)
                              }}
                                   className={`number-panel__forgot-password ${currentStep !== 1 && 'number-panel__button_empty'}`}>{value}</div>
                            )
                          })}
                          <button onClick={clearCode} className="number-panel__backspace" disabled={!inputField.length}>
                            <Icon name="backspace"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <ModalForgotPassword showModal={showModalNewPass} setShowModal={setShowModalNewPass}/>
    </>
  )
}
export default ModalSettingsPassword
