export const SITE = 'https://barcelona.m-works.ru/api-doc/';

export const sipConfig = {
  realm: '188.120.230.120.sslip.io',
  impi: '0100',
  impu: 'sip:0100@188.120.230.120.sslip.io',
  password: '0ed189c2f2563213',
  display_name: 'USER',
  websocket_proxy_url: 'wss://188.120.230.120.sslip.io:8089/ws'
};

export const pushPublicKey = 'BLP7vBVd7p2xqOy43sYi-9i8Qd-0cCnhjHvocHNP819uCkHCH2kaKgIBto0pu1gBYY647MOR9XS_2u6GGMnb-xE'