export function getScrollbarWidth() {

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;

}

export const scrollWidth = (status) => {

  const body = document.querySelector("body");

  if (status === true) {
    body.classList.add('no--scroll');
  } else {
    body.classList.remove('no--scroll');
  }

  if (body.classList.contains('no--scroll')) {
    body.style.paddingRight = getScrollbarWidth() + 'px';
  } else {
    body.style.paddingRight = '';
  }

}